
    import { defineComponent, ref, onMounted } from "vue";
    import DateRange from "@/components/daterange/DateRange.vue";
    import Agencies from "@/views/agencies/AgenciesView.vue";
    import SolanaServices from "@/core/services/SolanaServices";
    import axios from "axios";
    
    interface dashboardDataAdmin {
        avaluo_domicilio: {
            total: number, 
            comprados: number, 
            cancelado: number, 
            en_documentacion: number, 
            en_negociacion: number
        },
        citas_avaluo_domicilio: {
            total: number, 
            pendientes: number, 
            canceladas: number, 
            atendida: number, 
            efectivas: number
        },
        marcas_populares: any[],
        modelos_populares: any[],
        vehiculos_vendidos: number,
        avaluos_en_agencia: number,
        citas_agendadas_en_sitio: number
    }

    export default defineComponent({
        name: "Dashboard",
        components: {
            DateRange,
            Agencies
        },
        setup() {
            const dashboardDataAdmin = ref<dashboardDataAdmin>({
                avaluo_domicilio: {
                    total: 0, 
                    comprados: 0, 
                    cancelado: 0, 
                    en_documentacion: 0, 
                    en_negociacion: 0
                },
                citas_avaluo_domicilio: {
                    total: 0, 
                    pendientes: 0, 
                    canceladas: 0, 
                    atendida: 0, 
                    efectivas: 0
                },
                marcas_populares: [
                    {
                        icon: "",
                        marca: "-",
                        total: 0,
                        rating: 0,
                    },
                    {
                        icon: "",
                        marca: "-",
                        total: 0,
                        rating: 0,
                    },
                    {
                        icon: "",
                        marca: "-",
                        total: 0,
                        rating: 0,
                    },
                ],
                modelos_populares: [
                    {
                        icon: "",
                        marca: "-",
                        total: 0,
                        rating: 0,
                    },
                    {
                        icon: "",
                        marca: "-",
                        total: 0,
                        rating: 0,
                    },
                    {
                        icon: "",
                        marca: "-",
                        total: 0,
                        rating: 0,
                    },
                ],
                vehiculos_vendidos: 0,
                avaluos_en_agencia: 0,
                citas_agendadas_en_sitio: 0,
            });
            const tipoRol = ref<string>("");
            const firstDate = ref<string>("");
            const secondDate = ref<string>("");
            const placesHolderDates = ref<string>("");
            const getTipoRol = async () => {
                const {data} = await SolanaServices.getTipoRol();
                tipoRol.value = data.data[0].type;
            }

            /**
             * @method getDates
             * @params none
             * @return dates from calendar
             */
            const getDates = async (evt) => {
                if(evt.firstDate != undefined){
                    firstDate.value = evt.firstDate;
                }
                if(evt.secondDate != undefined){
                    secondDate.value = evt.secondDate;
                }
                if(firstDate.value != '' && secondDate.value != ''){
                    const {data} = await axios.get(process.env.VUE_APP_API_URL+'/api/dashboard/metrics?from='+firstDate.value+'&to='+secondDate.value);
                    dashboardDataAdmin.value = data.data;
                }
            };

            onMounted(async() => { 
                var date = new Date();
                var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
                var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                //FirsDate
                let dayFormat = "";
                let mesFormat = "";
                let month = firstDay.getMonth() + 1;
                let day = firstDay.getDate();
                if(day.toString().length == 1){
                    dayFormat = '0'+day;
                }else dayFormat = day.toString();
                if(month.toString().length == 1){
                    mesFormat = '0'+month;
                }else mesFormat = month.toString();
                //LastDate
                let ldayFormat = "";
                let lmesFormat = "";
                let lmonth = lastDay.getMonth() + 1;
                let lday = lastDay.getDate();
                if(lday.toString().length == 1){
                    ldayFormat = '0'+lday;
                }else ldayFormat = lday.toString();
                if(lmonth.toString().length == 1){
                    lmesFormat = '0'+lmonth;
                }else lmesFormat = lmonth.toString();
                placesHolderDates.value = dayFormat + '/' + mesFormat + '/' + firstDay.getFullYear() + ' - ' + ldayFormat + '/' + lmesFormat + '/' + lastDay.getFullYear();
                let fechaFrom = firstDay.getFullYear()+'-'+mesFormat+'-'+dayFormat;
                let fechaTo = lastDay.getFullYear()+'-'+lmesFormat+'-'+ldayFormat;
                await getTipoRol();
                if(tipoRol.value != 'Múltiples agencias' && tipoRol.value != 'Ciudad'){
                    if(tipoRol.value == 'Corporativo'){
                        const {data} = await SolanaServices.getDashboard("",fechaFrom, fechaTo);
                        dashboardDataAdmin.value = data.data;
                    }
                }
            });

            return {
                dashboardDataAdmin, getTipoRol, tipoRol, getDates, firstDate, secondDate, placesHolderDates
            };
        }
    });
